<template>
    <slot v-bind="{
        slideNext,
        slidePrev,
    }"
    />
</template>

<script lang="ts" setup>

const { injected } = useCoreSwiperWrapperProvide()

function slideNext() {
    injected?.swiper.value?.slideNext()
}

function slidePrev() {
    injected?.swiper.value?.slidePrev()
}

</script>

<style lang="scss" scoped>

</style>
